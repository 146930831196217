import { Builder } from '@builder.io/react'
import Body from './body'

Builder.registerComponent(Body, {
  name: 'Legal Body',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: '<p>Body text here...</p>',
    },
  ],
  noWrap: true,
  defaultStyles: {
    margin: '0 auto',
    maxWidth: '81.5rem',
  },
})
