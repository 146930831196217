import { Builder } from '@builder.io/react'
import Table from './table'

Builder.registerComponent(Table, {
  name: 'Legal Table',
  inputs: [
    {
      name: 'rows',
      type: 'list',
      subFields: [
        {
          name: 'columns',
          type: 'list',
          subFields: [
            { name: 'name', type: 'text', defaultValue: 'Name', required: true },
            { name: 'isBold', type: 'boolean', defaultValue: false },
          ],
        },
      ],
      defaultValue: [
        {
          columns: [
            { name: 'Cell in Bold', isBold: true },
            { name: 'Cell 2', isBold: false },
            { name: 'Cell 3', isBold: false },
          ],
        },
      ],
    },
  ],
  noWrap: true,
})
