import React from 'react'
import {
  TableContainer as MUITableContainer,
  Table as MUITable,
  TableBody as MUITableBody,
  TableRow as MUITableRow,
  TableCell as MUITableCell,
} from '@mui/material'
import { BuilderCustomComponentProps } from '../../types/builder-types'

interface TableProps extends BuilderCustomComponentProps {
  rows: { columns: { name: string; isBold: boolean }[] }[]
}

const Table = ({ rows, attributes }: TableProps) => {
  if (!rows) return null

  return (
    <MUITableContainer {...attributes}>
      <MUITable>
        <MUITableBody>
          {rows.map(({ columns }, i) => (
            <MUITableRow key={i}>
              {columns.map(({ name, isBold }, j) => (
                <MUITableCell key={`${name}-${j}`}>{isBold ? <b>{name}</b> : name}</MUITableCell>
              ))}
            </MUITableRow>
          ))}
        </MUITableBody>
      </MUITable>
    </MUITableContainer>
  )
}

export default Table
