import React from 'react'
import { StyledTitle, StyledParagraph } from './body.styles'
import { BuilderCustomComponentProps } from '../../types/builder-types'
import { RichTextReplacements, parseBuilderRichText } from '../../helpers/builderRichTextParser'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

interface BodyProps extends BuilderCustomComponentProps {
  text: string
}

const Body = ({ text, attributes }: BodyProps) => {
  const replacements: RichTextReplacements = [
    {
      nodeName: 'h1',
      Component(props) {
        return <StyledTitle variant="h1">{props.children}</StyledTitle>
      },
    },
    {
      nodeName: 'h2',
      Component(props) {
        return <StyledTitle variant="h2">{props.children}</StyledTitle>
      },
    },
    {
      nodeName: 'h3',
      Component(props) {
        return <StyledTitle variant="h3">{props.children}</StyledTitle>
      },
    },
    {
      nodeName: 'h4',
      Component(props) {
        return <StyledTitle variant="h4">{props.children}</StyledTitle>
      },
    },
    {
      nodeName: 'p',
      Component(props) {
        return <StyledParagraph variant="body1">{props.children}</StyledParagraph>
      },
    },
    {
      nodeName: 'ol',
      Component: 'ol',
    },
    {
      nodeName: 'ul',
      Component: 'ul',
    },
    {
      nodeName: 'table',
      Component(props) {
        return (
          <TableContainer>
            <Table>
              <TableBody>{props.children}</TableBody>
            </Table>
          </TableContainer>
        )
      },
    },
    {
      nodeName: 'tr',
      Component: TableRow,
    },
    {
      nodeName: 'th',
      Component: TableCell,
    },
    {
      nodeName: 'td',
      Component: TableCell,
    },
  ]

  return <section {...attributes}>{parseBuilderRichText(text, replacements)}</section>
}

export default Body
