import React from 'react'
import { Builder } from '@builder.io/react'
import Title from './genericTitle'

Builder.registerComponent(Title, {
  name: 'Title',
  inputs: [
    { name: 'text', type: 'text', defaultValue: 'This is a Title', required: true },
    {
      name: 'variant',
      type: 'text',
      defaultValue: 'h1',
      regex: {
        pattern: 'h1|h2|h3|h4|h5|h6|subtitle1|subtitle2|body1|body2|caption|button|overline',
        message:
          'Tag must be a valid MUI Typography variant, e.g. h1, h2, h3, h4, h5, h6, subtitle1, subtitle2, body1, body2, caption, button, overline',
      },
      required: true,
    },
  ],
  noWrap: true,
  defaultStyles: {
    fontFamily: 'Poppins, sans-serif',
  },
})
