import React, { ReactNode } from 'react'
import { createTheme, ThemeProvider as MUIThemeProvider, useTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    secondary: {
      main: '#EFF1ED',
    },
    text: {
      primary: '#232129',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1320,
    },
  },
})

// TODO: See if React exposes a default type with just children instead - reuse for other former React.FC components
interface IThemeProvider {
  children?: ReactNode
}
const ThemeProvider = ({ children }: IThemeProvider) => {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}

export { ThemeProvider, useTheme }
