import { styled, Typography } from '@mui/material'

export const StyledLastUpdate = styled('div')({
  fontFamily: 'Poppins, sans-serif',
})

export const StyledOl = styled('ol')({
  marginLeft: '3rem',
  marginBottom: '4rem',
  listStyle: 'none',
  counterReset: 'custom-counter',
  li: {
    position: 'relative',
    counterIncrement: 'custom-counter',
  },
  'li::before': {
    position: 'absolute',
    marginLeft: '-2em',
    content: 'counter(custom-counter) "."',
    fontWeight: '700',
    fontSize: '1.8rem',
  },
})

export const StyledUl = styled('ul')({
  listStyleType: 'none',
  marginLeft: '3rem',
  marginBottom: '4rem',
  li: {
    position: 'relative',
    marginBottom: '3rem',
    fontWeight: 700,
  },
  'li::before': {
    position: 'absolute',
    content: '"•"',
    fontWeight: 'bold',
    fontSize: '2rem',
    display: 'inline-block',
    width: '15px',
    top: 0,
    marginLeft: '-1em',
  },
})

export const StyledParagraph = styled(Typography)({
  fontFamily: 'Poppins, sans-serif',
  marginBottom: '3rem',
  lineHeight: '1.5',
  'td &': {
    marginBottom: 0,
  },
})

export const StyledTitle = styled(Typography)({
  fontFamily: 'Poppins, sans-serif',
  lineHeight: '1.1',
})
