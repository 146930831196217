import React from 'react'
import { BuilderCustomComponentProps } from '../../types/builder-types'
import { Typography } from '@mui/material'

interface GenericTitleProps extends BuilderCustomComponentProps {
  variant: string
  text: string
}

/**
 * Generic Title component for any type of title with custom tag in Builder.io
 * This is to be able to use custom tags for titles in Builder.io and also style them
 **/
const GenericTitle = ({ variant = 'h1', text, attributes }: GenericTitleProps) => {
  return (
    <Typography {...attributes} variant={variant}>
      {text}
    </Typography>
  )
}

export default GenericTitle
